<template>
  <div>
    <el-upload
      ref="img"
      action=""
      :list-type="listType"
      :on-preview="preview"
      :auto-upload="false"
      :limit="limit"
      :on-exceed="exceed"
      :http-request="httpRequest"
      v-bind="$attrs"
      :accept="accept"
      :on-change="change"
      :on-remove="remove"
      v-on="$listeners"
    >
      <i class="el-icon-plus" />
    </el-upload>
    <el-dialog :visible.sync="previewVisible" append-to-body>
      <div style="text-align: center;">
        <img class="normal-dialog-img-size" :src="previewUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseNotify from '@/libs/mixin/BaseNotify'
import { checkImageType } from '@/libs/valid/imageValid'
import { checkAudioType } from '@/libs/valid/audioValid'
import { checkVideoType } from '@/libs/valid/videoValid'
import { saveFileByFormData, removeFile } from '@/api/file'
import { hasFileUpload } from '@/libs/valid/fileUploadValid'

export default {
  name: 'YUploader',
  mixins: [BaseNotify],
  props: {
    limit: {
      type: Number,
      default: 1
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    accept: {
      type: String,
      default: 'image/*'
    }
  },
  data() {
    return {
      deleteImgIdList: [],
      deleteOssImageList: [],
      promiseList: [],
      previewVisible: false,
      previewUrl: '',
      dataId: '',
      payload: []
    }
  },
  methods: {
    exceed() {
      this.warningTip('选择文件超出上限')
    },
    change(file) {
      if (this.accept === 'image/*' && !checkImageType(file)) {
        this.warningTip('选择文件类型错误')
        this.$refs.moduleImg.clearFiles()
      } else if (this.accept === 'audio/*' && !checkAudioType(file)) {
        this.warningTip('选择文件类型错误')
        this.$refs.moduleImg.clearFiles()
      } else if (this.accept === 'video/*' && !checkVideoType(file)) {
        this.warningTip('选择文件类型错误')
        this.$refs.moduleImg.clearFiles()
      }
    },
    remove(file) {
      if (file.id) {
        // 删除的id保存下来
        this.deleteImgIdList.push(file.id)
      }
    },
    /**
     * payload: List{key, value}
     */
    upload(dataId, payload) {
      if (!dataId) {
        return
      }
      this.dataId = dataId
      if (payload) {
        this.payload = payload
      }
      return new Promise((resolve, reject) => {
        if (this.deleteImgIdList.length > 0) {
          this.removeFile()
        }
        if (hasFileUpload(this.$refs.img)) {
          this.$refs.img.submit()
          Promise.all(this.promiseList).then(res => {
            this.promiseList = []
            this.$refs.img.clearFiles()
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        } else {
          resolve()
        }
      })
    },
    removeFile() {
      this.deleteImgIdList.forEach(e => {
        const p = removeFile(e)
        this.promiseList.push(p)
      })
    },
    httpRequest(file) {
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('targetId', this.dataId)
      if (this.payload.length > 0) {
        this.payload.forEach(e => {
          formData.append(e.key, e.value)
        })
      }
      const p = saveFileByFormData(formData)
      this.promiseList.push(p)
    },
    preview(file) {
      this.previewUrl = file.url
      this.previewVisible = true
    }
  }
}
</script>

<style>

</style>
