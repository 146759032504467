<template>
  <div>
    <!-- 如果只有一个子节点，那么此节点直接成为父级节点 -->
    <!-- <template v-if="item.children && item.children.length === 1">
      <el-menu-item :index="item.children[0].path">
        <i :class="item.children[0].icon === '#' ? item.icon : item.children[0].icon" />
        <span slot="title">{{ item.children[0].title }}</span>
      </el-menu-item>
    </template> -->
    <!-- 没有子节点 -->
    <template v-if="!item.children">
      <el-menu-item :index="item.id">
        <i :class="item.icon" />
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </template>
    <!-- 如果拥有多个子节点，则为下级菜单继续展开 -->
    <template v-else>
      <el-submenu :index="item.id">
        <template slot="title">
          <i :class="item.icon" />
          <span slot="title">{{ item.title }}</span>
        </template>
        <side-menu-item v-for="child in item.children" :key="child.name" :item="child" class="nest-menu" />
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SideMenuItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
</style>
