<template>
  <div>
    <div v-if="defaultToolBar" class="defaultToolBar">
      <slot name="toolbar">
        <el-button
          v-if="hasRole('add')"
          type="primary"
          style="margin-bottom:5px;"
          size="small"
          plain
          @click="handleAdd()"
        >新增</el-button>
      </slot>
    </div>
    <div class="operateToolBar">
      <el-button
        v-if="showTool('export')"
        plain
        size="small"
        title="导出EXCEL"
        class="simple-button"
        :loading="exportLoading"
        @click="exportExcel()"
      >
        <svg-icon icon-class="excel" />
      </el-button>
      <slot name="operateToolBar" />
    </div>
    <el-table
      ref="elTable"
      v-loading="loading"
      v-bind="$attrs"
      :size="size"
      :data="tableData"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-on="$listeners"
    >
      <el-table-column v-if="hasIndex" type="index" />
      <slot />
      <slot name="operating">
        <el-table-column v-if="defaultOperating" fixed="right" label="操作" :width="operatingColumnWidth">
          <template slot-scope="scope">
            <el-button
              v-if="hasRole('detail')"
              type="text"
              size="small"
              @click="handleDetail(scope.row)"
            >查看</el-button>
            <el-button
              v-if="hasRole('edit')"
              type="text"
              size="small"
              @click="handleEdit(scope.row)"
            >编辑</el-button>
            <el-popconfirm
              v-if="hasRole('delete')"
              confirm-button-text="确认"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              class="popButton"
              title="确认删除该数据吗？"
              @confirm="handleDelete(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="small"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </slot>
    </el-table>
    <el-pagination
      v-if="!isLocalData && pagination"
      class="pagination"
      :current-page="currentPage"
      :page-size="currentPageSize"
      :background="true"
      :layout="paginationLayout"
      :total="total"
      :pager-count="pagerCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { tableQueryByGet, tableQueryByPost } from '@/api/table'

export default {
  name: 'tableCommon',
  props: {
    // 是否需要第一列序号列
    hasIndex: {
      type: Boolean,
      default: true
    },
    // table size
    size: {
      type: String,
      default: 'medium'
    },
    // table查询的url
    url: {
      type: String,
      default: ''
    },
    // 当前页的行数
    pageSize: {
      type: Number,
      default: 10
    },
    // 当前第几页
    page: {
      type: Number,
      default: 1
    },
    // 查询条件
    where: {
      type: Object,
      default: () => {}
    },
    // 是否显示默认的toolbar
    defaultToolBar: {
      type: Boolean,
      default: true
    },
    // 是否显示默认的操作列
    defaultOperating: {
      type: Boolean,
      default: true
    },
    // 操作按钮的权限名
    buttonRoleName: {
      type: Object,
      default: () => {}
    },
    // 是否显示默认工具栏
    operateToolBar: {
      type: String,
      default: ''
    },
    // 是否是本地数据(如果为true则该表格不会显示分页)
    isLocalData: {
      type: Boolean,
      default: false
    },
    // 本地数据，如果要使本地数据起作用，一定要设置isLocalData = true
    localData: {
      type: Array,
      default: () => []
    },
    // 操作列宽度
    operatingColumnWidth: {
      type: String,
      default: '150'
    },
    // 是否分页
    pagination: {
      type: Boolean,
      default: true
    },
    // table的加载效果
    tableLoading: {
      type: Boolean,
      default: null
    },
    // table请求方式，默认是get
    method: {
      type: String,
      default: 'get'
    },
    // 是否请求数据
    autoLoad: {
      type: Boolean,
      default: true
    },
    paginationLayout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pagerCount: {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      currentPageSize: 10,
      tableData: [],
      total: 0,
      exportLoading: false
    }
  },
  watch: {
    localData(newVal) {
      if (this.isLocalData) {
        this.reload()
      }
    },
    tableLoading(newVal) {
      this.loading = newVal
    }
  },
  mounted() {
    // 非本地数据
    if (!this.isLocalData) {
      this.currentPage = this.page
      this.currentPageSize = this.pageSize
    }
    if (!this.autoLoad) {
      return
    }
    this.query()
  },
  methods: {
    handleAdd() {
      this.$emit('add')
    },
    handleDetail(data) {
      this.$emit('detail', data)
    },
    handleEdit(data) {
      this.$emit('edit', data)
    },
    handleDelete(data) {
      this.$emit('delete', data)
    },
    handleSizeChange(val) {
      // 每页条数改变
      this.currentPageSize = val
      this.query()
    },
    handleCurrentChange(val) {
      // 当前页改变
      this.currentPage = val
      this.query()
    },
    query() {
      this.loading = true
      if (this.isLocalData) {
        this.tableData = this.localData
        this.loading = false
        return
      }
      if (this.url === '') {
        return
      }
      const params = Object.assign(
        { page: this.currentPage, limit: this.currentPageSize },
        this.where
      )
      if (this.method === 'get') {
        tableQueryByGet(this.url, params).then((res) => {
          this.tableData = res.data.list
          this.total = res.data.totalCount
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      } else if (this.method === 'post') {
        tableQueryByPost(this.url, params).then((res) => {
          this.tableData = res.data.list
          this.total = res.data.totalCount
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    },
    reload(resetPage) {
      this.$nextTick(() => {
        if (resetPage && !this.isLocalData) {
          this.currentPage = 1
        }
        this.query()
      })
    },
    hasRole(symbol) {
      if (this.buttonRoleName && this.buttonRoleName[symbol]) {
        return this.$store.getters.hasRole(this.buttonRoleName[symbol])
      } else {
        return true
      }
    },
    exportExcel() {
      const header = this.$refs.elTable.$children
        .filter((e) => e.prop)
        .map((f) => f.prop)
      this.exportLoading = true
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = header
        const list = this.tableData
        const data = this.formatJson(tHeader, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'excel-list',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.exportLoading = false
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j]
        })
      )
    },
    clearSelection() {
      this.$refs.elTable.clearSelection()
    },
    showTool(buttonName) {
      if (this.operateToolBar && this.operateToolBar.split(',').includes(buttonName)) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  margin: 12px 0 0 0;
}
.simple-button {
  padding: 8px 10px;
  margin: 4px 5px;
}
.operateToolBar {
  float: right;
}
.defaultToolBar {
  float: left;
}
</style>
