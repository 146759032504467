import Vue from 'vue'
import App from './App.vue'
// vue router
import router from './router'
// vue store
import store from './store'
// 下拉框树插件
import ElSelectTree from 'el-select-tree'
// element
import './plugins/element.js'
// element ui
import ElementUI from 'element-ui'
// element css
import 'element-ui/lib/theme-chalk/index.css'
// 全局样式
import '@/style/global.scss'
// 全局组件
import './components'
// icon
import './icons'
// video
import 'video.js/dist/video-js.css'
Vue.config.productionTip = false
// vue-seamless-scroll
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// babel
import 'babel-polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// el-select-tree
Vue.use(ElSelectTree)
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
