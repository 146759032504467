<template>
  <div
    class="side-container"
    :class="collapse ? 'side-container-collapsed' : ''"
  >
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <div v-if="!collapse && title" class="menuTitle" @click="toHome">
        {{ title }}
      </div>
      <el-menu
        :background-color="menuColor.menuBg"
        :text-color="menuColor.menuText"
        :active-text-color="menuColor.menuTextActive"
        :default-active="active"
        :collapse="collapse"
        :unique-opened="false"
        mode="vertical"
        :collapse-transition="false"
        @select="onSelect"
      >
        <side-menu-item v-for="item in menuList" :key="item.name" :item="item" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SideMenuItem from './SideMenuItem'
import menuColor from './menu.scss'

export default {
  components: {
    SideMenuItem
  },
  props: {
    active: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    menuList() {
      return this.$store.getters.menuList
    },
    menuColor() {
      return menuColor
    },
    menuInfoMap() {
      return this.$store.getters.getMenuInfo
    }
  },
  methods: {
    onSelect(index) {
      const path = this.menuInfoMap.get(index).path
      this.$emit('on-select', path)
    },
    toHome() {
      this.$emit('on-select', process.env.VUE_APP_HOME_PATH)
    }
  }
}
</script>

<style lang="scss">
@import './menu.scss';
</style>
