import Layout from '../views/Layout/Layout.vue'

export const constRoute = [{
  path: '/',
  name: 'Layout',
  component: Layout,
  redirect: '/edu-dashboard',
  children: [{
    path: '/home',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: () =>
      import ('@/views/home/Home.vue')
  }]
},
{
  path: '/login',
  name: 'login',
  component: () =>
    import ('@/views/login/Login.vue')
},
{
  path: '/edu-dashboard',
  name: 'edu-dashboard',
  component: () =>
    import ('@/views/edu/EduDashboard.vue')
},
{
  path: '/camera-video',
  name: 'camera-video',
  component: () =>
    import ('@/views/camera/Camera.vue'),
  props: route => ({ school: route.query.school })
},
{
  path: '/school-dashboard',
  name: 'school-dashboard',
  component: () =>
    import ('@/views/school/SchoolDashboard.vue'),
  props: route => ({ school: route.query.school })
},
{
  path: '/schooltv-tv',
  name: 'schooltv-tv',
  component: () =>
    import ('@/views/schooltv/tv.vue'),
  props: route => ({ school: route.query.school })
},
{
  path: '/error',
  component: Layout,
  children: [{
    path: '404',
    name: '404',
    meta: {
      title: '404'
    },
    component: () =>
      import ('@/views/404/page404.vue')
  }]
},
{ path: '*', redirect: '/error/404', meta: { hideInMenu: true }}
]
