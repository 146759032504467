<template>
  <div class="user-menu">
    <el-dropdown @command="setting">
      <span class="el-dropdown-link">
        {{ userName }}<i class="el-icon-arrow-down el-icon--right" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="signOut">登出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'userMenu',
  computed: {
    userName() {
      return this.$store.getters.getNickName
    }
  },
  methods: {
    setting(type) {
      if (type !== 'signOut') {
        this.$emit('toPage', type)
      } else {
        this.$store.commit('closeAllTag')
        this.$store.dispatch('sysLogout')
        this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

<style lang=scss>
  .user-menu {
    letter-spacing: 1px;

    & .el-dropdown-link {
      cursor: pointer;
      color: #000;
    }

    & .el-icon-arrow-down {
      font-size: 12px;
    }
  }

</style>
