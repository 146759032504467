<template>
  <div class="screen-ctrl" @click="click">
    <i class="el-icon-full-screen" />
  </div>
</template>

<script>
import screenfull from 'screenfull'

export default {
  name: 'screenFullBtn',
  methods: {
    click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    }
  }
}
</script>

<style lang="scss" scoped>
  .screen-ctrl {
    font-size: 16px;
    cursor: pointer;
  }
</style>
