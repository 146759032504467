<template>
  <div class="tags-view">
    <div class="tag-view">
      <div class="close-control">
        <el-dropdown @command="close">
          <el-button type="text">
            <i class="el-icon-close" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="closeOther">关闭其他</el-dropdown-item>
            <el-dropdown-item command="closeAll">关闭所有</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="btn-con left-btn">
        <el-button type="text" @click="moveScroll(240)">
          <i class="el-icon-arrow-left" />
        </el-button>
      </div>
      <div class="btn-con right-btn">
        <el-button type="text" @click="moveScroll(-240)">
          <i class="el-icon-arrow-right" />
        </el-button>
      </div>
      <div ref="scrollOuter" class="scroll-outer" @DOMMouseScroll="mouseScroll" @mousewheel="mouseScroll">
        <div ref="scrollBody" class="scroll-body" :style="{left: tagBodyLeft + 'px'}">
          <tag
            v-for="tag in tagsList"
            :key="tag.name"
            ref="tagsOpened"
            :tag="tag"
            :route-tag="tag"
            :active="isActive(tag.path)"
            @select-tag="selectTag"
            @close="close"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tag from './Tag'
import { isCurrentPath } from '@/libs/util'

export default {
  components: {
    tag
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tagBodyLeft: 0,
      rightOffset: 40,
      outerPadding: 4,
      contextMenuLeft: 0,
      contextMenuTop: 0,
      visible: false
    }
  },
  computed: {
    currentPath() {
      return this.value
    },
    tagsList() {
      return this.$store.state.tags.tagsList
    }
  },
  watch: {
    '$route'(to) {
      this.moveTagsToCurrentTag(to)
    }
  },
  methods: {
    isActive(name) {
      return isCurrentPath(this.currentPath, name)
    },
    selectTag(name, query) {
      this.$emit('select-tag', name, query)
    },
    close(type, tag) {
      if (type === 'closeOther') {
        this.$emit('close', 'other', this.$route)
        this.moveTagsToCurrentTag(this.$route)
      } else if (type === 'closeAll') {
        this.$emit('close', 'all')
      } else if (type === 'single') {
        this.$emit('close', 'single', tag)
      }
    },
    mouseScroll(e) {
      var type = e.type
      let delta = 0
      if (type === 'DOMMouseScroll' || type === 'mousewheel') {
        delta = (e.wheelDelta) ? e.wheelDelta : -(e.detail || 0) * 40
      }
      this.moveScroll(delta)
    },
    moveScroll(offset) {
      const outerWidth = this.$refs.scrollOuter.offsetWidth
      const bodyWidth = this.$refs.scrollBody.offsetWidth
      if (offset > 0) {
        this.tagBodyLeft = Math.min(0, this.tagBodyLeft + offset)
      } else {
        if (outerWidth < bodyWidth) {
          if (this.tagBodyLeft < -(bodyWidth - outerWidth)) {
            // this.tagBodyLeft = this.tagBodyLeft
          } else {
            this.tagBodyLeft = Math.max(this.tagBodyLeft + offset, outerWidth - bodyWidth)
          }
        } else {
          this.tagBodyLeft = 0
        }
      }
    },
    moveToView(tag) {
      const outerWidth = this.$refs.scrollOuter.offsetWidth
      const bodyWidth = this.$refs.scrollBody.offsetWidth
      if (bodyWidth < outerWidth) {
        this.tagBodyLeft = 0
      } else if (tag.offsetLeft < -this.tagBodyLeft) {
        // 标签在可视区域左侧
        this.tagBodyLeft = -tag.offsetLeft + this.outerPadding
      } else if (tag.offsetLeft > -this.tagBodyLeft && tag.offsetLeft + tag.offsetWidth < -this.tagBodyLeft + outerWidth) {
        // 标签在可视区域
        this.tagBodyLeft = Math.min(0, outerWidth - tag.offsetWidth - tag.offsetLeft - this.outerPadding)
      } else {
        // 标签在可视区域右侧
        this.tagBodyLeft = -(tag.offsetLeft - (outerWidth - this.outerPadding - tag.offsetWidth))
      }
    },
    moveTagsToCurrentTag(name) {
      // 等待dom异步渲染完成
      // example: https://www.jianshu.com/p/a7550c0e164f
      this.$nextTick(() => {
        this.refsTag = this.$refs.tagsOpened
        this.refsTag.forEach((item, index) => {
          if (isCurrentPath(name, item.$attrs['route-tag'].path)) {
            const tag = this.refsTag[index].$el
            this.moveToView(tag)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import "./tag.scss"
</style>
