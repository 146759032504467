<template>
  <div>
    <div class="header">
      <collapse />
      <div class="right-menu">
        <screenFull class="right-menu-item" />
        <userMenu class="right-menu-item" @toPage="to" />
      </div>
    </div>
    <tags-view :value="$route" @select-tag="to" @close="removeTag" />
  </div>
</template>

<script>
import collapse from './menuCollapse/Collapse'
import screenFull from './screenFull/ScreenFull'
import userMenu from './userMenu/UserMenu'
import { TagsView } from '../'

export default {
  components: {
    collapse,
    screenFull,
    userMenu,
    TagsView
  },
  methods: {
    to(type, query) {
      this.$emit('to', type, query)
    },
    logout() {
      this.$emit('logout')
    },
    removeTag(type, route) {
      this.$emit('close', type, route)
    }
  }
}
</script>

<style lang="scss">
@import './header.scss';
</style>
