<template>
  <div>
    <div class="banner" />
    <div>
      <SideMenu :active="active" :collapse="getCollapse" :title="systemName" @on-select="to" />
    </div>
    <div class="main-container" :class="{hideSidebar: classes}">
      <div class="app-header" :class="{headerHideSidebar: classes}">
        <Header @to="to" @close="removeTag" />
      </div>
      <div>
        <div class="app-main">
          <div class="app-inner">
            <transition name="fade-transform" mode="out-in">
              <keep-alive :include="getCacheList">
                <router-view />
              </keep-alive>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SideMenu, Header } from './components'
import { isCurrentPath } from '@/libs/util'
import { mapMutations, mapGetters } from 'vuex'
import { isExternal } from '@/libs/util'

export default {
  name: 'layout',
  components: {
    SideMenu,
    Header
  },
  data() {
    return {
      systemName: '',
      active: ''
    }
  },
  computed: {
    ...mapGetters([
      'getCollapse',
      'getTagsList'
    ]),
    getCacheList() {
      return this.getTagsList.map(item => item.name)
    },
    classes() {
      return this.getCollapse
    }
  },
  watch: {
    '$route'(newRouter) {
      const { name, query, params, meta, path } = newRouter
      this.addTag({
        tag: { name, query, params, meta, path }
      })
      this.active = newRouter.meta.id
    }
  },
  mounted() {
    this.systemName = process.env.VUE_APP_SYSTEM_NAME
    const { name, query, params, meta, path } = this.$route
    this.addTag({
      tag: { name, query, params, meta, path }
    })
    this.active = this.$route.meta.id
  },
  methods: {
    ...mapMutations([
      'addTag',
      'closeAllTag',
      'closeOtherTag',
      'closeTag'
    ]),
    to(path, query) {
      if (!isCurrentPath(this.$route, path)) {
        if (isExternal(path)) {
          window.open(path, '_blank')
        }
        this.$router.push({
          path,
          query
        })
      }
    },
    removeTag(type, route) {
      if (type === 'all') {
        this.closeAllTag()
        this.to(process.env.VUE_APP_HOME_PATH)
      } else if (type === 'other') {
        if (!route) {
          return
        }
        const { name, query, params, meta, path } = route
        this.closeOtherTag({ name, query, params, meta, path })
      } else if (type === 'single') {
        const { path } = route
        const currentRoute = this.$route
        this.closeTag({ currentRoute, path })
      }
    }
  }
}
</script>

<style>
</style>
