<template>
  <el-tag
    key="tag.name"
    :closable="tag.name !== 'home'"
    :type="tag.type ? tag.type : ''"
    :class="active ? 'active' : ''"
    effect="plain"
    disable-transitions
    @click.native.prevent="select"
    @close="close"
  >
    {{ tag.title }}
  </el-tag>
</template>

<script>

export default {
  props: {
    tag: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    select() {
      this.$emit('select-tag', this.tag.path, this.tag.query)
    },
    close() {
      this.$emit('close', 'single', this.tag)
    }
  }
}
</script>

<style lang="scss">

</style>
