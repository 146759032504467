import { constRoute } from '@/router/routers'
import router, { resetRouter } from '@/router'
import { getRoleMenu } from '@/api/main'
import { toTree } from '@/libs/tree'
// import { getMenu, hasChild } from '@/libs/util'

export default {
  state: {
    collapse: false,
    // 无权限控制菜单
    localMenu: [],
    // 权限控制菜单
    accessMenu: [],
    // 权限菜单
    roleMenu: [],
    // 菜单path map
    menuInfoMap: new Map()
  },
  getters: {
    menuList: (state) => state.localMenu,
    getRoleMenu: (state) => state.roleMenu,
    getCollapse: (state) => state.collapse,
    getMenuInfo: (state) => state.menuInfoMap
  },
  mutations: {
    triggerCollapse(state) {
      state.collapse = !state.collapse
    },
    setLocalMenu(state, localMenu) {
      state.localMenu = localMenu
    },
    setAccessMenu(state, accessMenu) {
      state.accessMenu = accessMenu
    },
    addRoleMenu(state, name) {
      state.roleMenu.push(name)
    },
    cleanMenu(state) {
      state.accessMenu = []
      state.roleMenu = []
    },
    setMapInfo(state, { id, menu }) {
      state.menuInfoMap.set(id, menu)
    }
  },
  actions: {
    async changeMenu({ state, commit, dispatch }) {
      // 获取整理完成的后台menu
      const accessRoutes = await dispatch('getAccessRoutes')
      // 整理 后台路径 保存至router / menu 中， 动态vue路由 和 菜单路由进行分离保存
      await dispatch('initLocalMenu', accessRoutes)
      // 获取vue动态路径
      const filterRouters = await dispatch('initAccessMenu', accessRoutes)
      // filterRouters.push({ path: '*', redirect: '/error/404', meta: { hideInMenu: true }})
      // 设置菜单路径
      constRoute[0].children = constRoute[0].children.concat(filterRouters)
      constRoute.push({
        path: '*',
        redirect: '/error/404',
        meta: { hideInMenu: true }
      })
      // 重置路由
      resetRouter()
      // 添加路由
      router.addRoutes(constRoute)
    },

    // 获取后台menu
    getAccessRoutes({ commit }) {
      return new Promise(resolve => {
        getRoleMenu().then(res => {
          commit('setNickName', res.data.name)
          const accessMenu = res.data.menuList
          resolve(accessMenu)
        })
      })
    },

    // 整理后台格式menu
    async initAccessMenu({ commit }, accessRoutes) {
      const temp = []
      accessRoutes.forEach(e => {
        if (e.path && /\S/.test(e.path)) {
          const r = {
            name: e.perms,
            path: e.path,
            component: null,
            meta: {
              id: e.id,
              title: e.name
            }
          }
          try {
            r.component = resolve => require([`@/views${e.path}`], resolve)
          } catch (e) {
            console.log(e)
          }
          temp.push(r)
        }
      })
      return temp
    },

    async initLocalMenu({ commit }, accessRoutes) {
      accessRoutes.forEach(e => {
        e.title = e.name
        if (!e.id) {
          e.id = Math.floor(Math.random() * 100)
        }
        commit('setMapInfo', { id: e.id, menu: e })
      })
      const localMenu = toTree(accessRoutes)
      commit('setLocalMenu', localMenu)
    },

    cleanMenuRouter({ commit }) {
      commit('cleanMenu')
      resetRouter()
    }
  }
}
