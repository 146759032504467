<template>
  <el-card class="cus-card" :class="className" :shadow="shadow" v-bind="$attrs">
    <slot slot="header" name="header" />
    <div>
      <slot />
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'yCard',
  props: {
    // class name 支持 nobody 具体样式请参考 formPage.scss
    className: {
      type: String,
      default: ''
    },
    shadow: {
      type: String,
      default: 'never'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
